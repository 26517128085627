var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.getLoadings.listings && !_vm.getErrors.listings),expression:"!getLoadings.listings && !getErrors.listings"}]},[_vm._t("default",function(){return [_c('div',{staticClass:"row mx-0"},[(_vm.listings.data.length > 0)?_vm._l((_vm.listings.data),function(listing,i){return _c('div',{key:listing.id,staticClass:"col-lg-4 col-sm-6 col-12"},[_c('BrokerListing',{attrs:{"listing":listing,"externalClass":['recent-listing'],"listingLink":`/listings/${listing.slug}`},scopedSlots:_vm._u([{key:"status",fn:function(){return [(
                  listing.currentStatus == 'cloned' || listing.currentStatus == 'draft'
                )?_c('div',{staticClass:"bg-gray text-white p-2 text-center"},[_vm._v(" "+_vm._s(_vm.$t("Draft"))+" ")]):_vm._e(),_c('router-link',{attrs:{"to":_vm.getAppointmentRoute(i)}},[(listing.new_message)?_c('div',{staticClass:"bg-secondary w-100 text-white p-2 text-center"},[_c('span',{staticClass:"pl-2 listing__caption__text"},[_vm._v(_vm._s(_vm.$t("new_message_received")))])]):_vm._e(),(
                    listing.appointments &&
                    listing.appointments.length &&
                    !listing.new_message
                  )?_c('div',{staticClass:"bg-primary w-100 text-white p-2 text-center",style:({ 'background-color': '#467599' })},[_c('span',{staticClass:"pl-2 listing__caption__text"},[_vm._v(" "+_vm._s(_vm.$t("messages"))+" : "+_vm._s(listing.appointments.length)+" ")])]):_vm._e()]),(
                  listing.appointments &&
                  listing.appointments.length &&
                  _vm.hasNewMessage(i)
                )?_c('div',{staticClass:"listing__caption text-white pl-3",style:({ 'background-color': '#54B16B' }),on:{"click":function($event){_vm.$route.push(_vm.getAppointmentRoute(i))}}},[_c('span',{staticClass:"pl-2 listing__caption__text"},[_vm._v(" "+_vm._s(_vm.$t("newMessage"))+" ")])]):_vm._e()]},proxy:true},{key:"controls",fn:function(){return [(listing.currentStatus == 'cloned')?_c('RouterLink',{staticClass:"btn btn-white text-primary btn-block",attrs:{"to":{
                  name: 'BrokerEditListing',
                  params: { listingId: listing.id, mode: 'edit' },
                }}},[_vm._v(" "+_vm._s(_vm.$t("Edit listing to publish"))+" ")]):(listing.currentStatus == 'rented')?_c('RouterLink',{staticClass:"btn btn-white text-primary btn-block",attrs:{"to":`/broker/listings/${listing.id}/assigned`}},[_vm._v(" "+_vm._s(_vm.$t("View assigned tenant"))+" ")]):(
                  listing.appointments &&
                  listing.appointments.length &&
                  ['Assign a candidate', 'Rented', 'Assigning Requested'].includes(
                    listing.currentStatus
                  )
                )?_c('RouterLink',{staticClass:"btn btn-white text-primary btn-block",attrs:{"to":_vm.getAppointmentRoute(i)}},[_vm._v(" "+_vm._s(_vm.$t("Assigning"))+" ")]):(listing.appointments && listing.appointments.length)?_c('RouterLink',{staticClass:"btn btn-white text-primary btn-block",attrs:{"to":_vm.getAppointmentRoute(i)}},[_vm._v(" "+_vm._s(_vm.$t("Invite candidate"))+" ")]):_c('div',{staticClass:"btn btn-white text-dark btn-block"},[_vm._v(" "+_vm._s(_vm.$t("No messages"))+" ")]),_c('div',{staticClass:"controls"},[_c('div',[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":_vm.copied ? 'Copied' : 'Copy link'},on:{"click":function($event){return _vm.copyListingLink(listing)}}},[_c('i',{staticClass:"fa-solid fa-link"})])]),_c('div',{staticClass:"d-flex"},[_c('button',{on:{"click":function($event){return _vm.toggleDeleteModal(listing.id, i)}}},[_c('i',{staticClass:"fa-regular fa-trash-can"})]),_c('button',{on:{"click":function($event){return _vm.clone(listing.id)}}},[_c('Duplicate')],1),_c('button',{staticClass:"d-flex",on:{"click":function($event){return _vm.$router.push({
                        name: 'BrokerEditListing',
                        params: { listingId: listing.id, mode: 'edit' },
                      })}}},[_c('Edit')],1)])])]},proxy:true}],null,true)})],1)}):_c('div',{staticClass:"col-md-12 justify-content-center text-gray text-center"},[(_vm.activeFilter == 'rented')?_c('div',[_vm._v(" "+_vm._s(_vm.$t("You don’t have any listings rented yet."))+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.$t("You don't have any listings yet."))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t( 'Please add a new listing by pressing the green button "Add listing" in the menu bar' ))+" ")])])],2)]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }