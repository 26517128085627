var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body p-0"},[_c('table',{staticClass:"table table-borderless table-hover"},[_c('thead',[_c('tr',{staticClass:"border-bottom"},[_c('th'),_c('th',[_vm._v(" "+_vm._s(_vm.$t("Address"))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t("Status"))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t("Price"))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t("Messages"))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t("Edit"))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t("Action"))+" ")])])]),_c('tbody',_vm._l((_vm.listings.data),function(listing,index){return _c('tr',{key:index,staticClass:"border-bottom"},[_c('td',{staticClass:"py-4"}),_c('td',{staticClass:"py-4"},[_vm._v(_vm._s(listing.title))]),_c('td',{staticClass:"py-4"},[(listing.currentStatus == 'Available')?_c('span',{staticClass:"badge badge-primary p-2"},[_vm._v(" "+_vm._s(_vm.$t(listing.currentStatus))+" ")]):(listing.currentStatus == 'cloned')?_c('span',{staticClass:"badge badge-secondary p-2"},[_vm._v(" "+_vm._s(_vm.$t("Draft"))+" ")]):_c('span',{staticClass:"badge badge-secondary p-2"},[_vm._v(" "+_vm._s(listing.currentStatus)+" ")])]),_c('td',{staticClass:"py-4 text-gray"},[_vm._v("€ "+_vm._s(listing.price))]),_c('td',{staticClass:"py-4 text-gray fw-700"},[_vm._v(_vm._s(listing.messages_count))]),_c('td',{staticClass:"py-4"},[_c('i',{staticClass:"fa-solid fa-pen-to-square text-gray3 cursor-pointer mr-2",on:{"click":function($event){return _vm.$router.push({
                      name: 'BrokerEditListing',
                      params: { listingId: listing.id, mode: 'edit' },
                    })}}}),_c('i',{staticClass:"fa-regular fa-clone text-gray3 cursor-pointer mr-2",on:{"click":function($event){return _vm.clone(listing.id)}}}),_c('i',{staticClass:"fa-regular fa-trash-can text-gray3 cursor-pointer mr-2",on:{"click":function($event){return _vm.toggleDeleteModal(listing.id, index)}}})]),_c('td',{staticClass:"py-4"},[(listing.currentStatus == 'rented')?_c('router-link',{staticClass:"btn btn-outline-primary btn-sm",attrs:{"to":`/broker/listings/${listing.id}/assigned`}},[_vm._v(" "+_vm._s(_vm.$t("View assigned tenant"))+" ")]):(
                    listing.appointments &&
                    listing.appointments.length &&
                    ['Assign a candidate', 'Rented', 'Assigning Requested'].includes(
                      listing.currentStatus
                    )
                  )?_c('router-link',{staticClass:"btn btn-outline-primary btn-sm",attrs:{"to":_vm.getAppointmentRoute(listing)}},[_vm._v(" "+_vm._s(_vm.$t("Assigning"))+" ")]):(listing.appointments && listing.appointments.length)?_c('router-link',{staticClass:"btn btn-outline-primary btn-sm",attrs:{"to":_vm.getAppointmentRoute(listing)}},[_vm._v(" "+_vm._s(_vm.$t("Invite candidates"))+" ")]):_vm._e()],1)])}),0)]),(_vm.listings.data.length == 0)?_c('div',{staticClass:"d-flex col-md-12 justify-content-center text-gray text-center"},[_vm._v(" "+_vm._s(_vm.$t("You don't have any listings yet."))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t( 'Please add a new listing by pressing the green button "Add listing" in the menu bar' ))+" ")]):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }