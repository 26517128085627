<template>
  <div v-show="!getLoadings.listings && !getErrors.listings">
    <slot>
      <div class="row mx-0">
        <template v-if="listings.data.length > 0">
          <div
            class="col-lg-4 col-sm-6 col-12"
            v-for="(listing, i) in listings.data"
            :key="listing.id"
          >
            <BrokerListing
              :listing="listing"
              :externalClass="['recent-listing']"
              :listingLink="`/listings/${listing.slug}`"
            >
              <template v-slot:status>
                <!--messages -->
                <div
                  class="bg-gray text-white p-2 text-center"
                  v-if="
                    listing.currentStatus == 'cloned' || listing.currentStatus == 'draft'
                  "
                >
                  {{ $t("Draft") }}
                </div>
                <router-link :to="getAppointmentRoute(i)">
                  <div
                    v-if="listing.new_message"
                    class="bg-secondary w-100 text-white p-2 text-center"
                  >
                    <span class="pl-2 listing__caption__text">{{
                      $t("new_message_received")
                    }}</span>
                  </div>
                  <div
                    v-if="
                      listing.appointments &&
                      listing.appointments.length &&
                      !listing.new_message
                    "
                    class="bg-primary w-100 text-white p-2 text-center"
                    :style="{ 'background-color': '#467599' }"
                  >
                    <span class="pl-2 listing__caption__text">
                      {{ $t("messages") }} :
                      {{ listing.appointments.length }}
                    </span>
                  </div>
                </router-link>

                <div
                  v-if="
                    listing.appointments &&
                    listing.appointments.length &&
                    hasNewMessage(i)
                  "
                  @click="$route.push(getAppointmentRoute(i))"
                  class="listing__caption text-white pl-3"
                  :style="{ 'background-color': '#54B16B' }"
                >
                  <span class="pl-2 listing__caption__text">
                    {{ $t("newMessage") }}
                  </span>
                </div>
              </template>
              <template v-slot:controls>
                <RouterLink
                  v-if="listing.currentStatus == 'cloned'"
                  class="btn btn-white text-primary btn-block"
                  :to="{
                    name: 'BrokerEditListing',
                    params: { listingId: listing.id, mode: 'edit' },
                  }"
                >
                  {{ $t("Edit listing to publish") }}
                </RouterLink>
                <RouterLink
                  v-else-if="listing.currentStatus == 'rented'"
                  class="btn btn-white text-primary btn-block"
                  :to="`/broker/listings/${listing.id}/assigned`"
                >
                  {{ $t("View assigned tenant") }}
                </RouterLink>
                <RouterLink
                  v-else-if="
                    listing.appointments &&
                    listing.appointments.length &&
                    ['Assign a candidate', 'Rented', 'Assigning Requested'].includes(
                      listing.currentStatus
                    )
                  "
                  class="btn btn-white text-primary btn-block"
                  :to="getAppointmentRoute(i)"
                >
                  {{ $t("Assigning") }}
                </RouterLink>
                <RouterLink
                  v-else-if="listing.appointments && listing.appointments.length"
                  class="btn btn-white text-primary btn-block"
                  :to="getAppointmentRoute(i)"
                >
                  {{ $t("Invite candidate") }}
                </RouterLink>
                <div v-else class="btn btn-white text-dark btn-block">
                  {{ $t("No messages") }}
                </div>
                <!-- <p v-else class="controls-text noMessage m-0 pb-2">
                  {{ $t("broker.noMessage") }}
                </p> -->
                <div class="controls">
                  <div>
                    <button
                      @click="copyListingLink(listing)"
                      v-b-tooltip.hover
                      :title="copied ? 'Copied' : 'Copy link'"
                    >
                      <i class="fa-solid fa-link"></i>
                    </button>
                  </div>
                  <div class="d-flex">
                    <button @click="toggleDeleteModal(listing.id, i)">
                      <i class="fa-regular fa-trash-can"></i>
                    </button>
                    <button @click="clone(listing.id)">
                      <Duplicate />
                    </button>
                    <button
                      @click="
                        $router.push({
                          name: 'BrokerEditListing',
                          params: { listingId: listing.id, mode: 'edit' },
                        })
                      "
                      class="d-flex"
                    >
                      <Edit />
                    </button>
                  </div>
                </div>
              </template>
            </BrokerListing>
          </div>
        </template>
        <div v-else class="col-md-12 justify-content-center text-gray text-center">
          <div v-if="activeFilter == 'rented'">
            {{ $t("You don’t have any listings rented yet.") }}
          </div>
          <div v-else>
            {{ $t("You don't have any listings yet.") }} <br />
            {{
              $t(
                'Please add a new listing by pressing the green button "Add listing" in the menu bar'
              )
            }}
          </div>
        </div>
      </div>
    </slot>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Duplicate from "@/components/svg/duplicate.vue";
import Edit from "@/components/svg/edit.vue";
import BrokerListing from "@/components/general/listing/Listing.vue";

export default {
  props: ["search", "activeFilter"],
  components: {
    Edit,
    Duplicate,
    BrokerListing,
  },
  data() {
    return {
      currentListingIdx: null,
      selectedListing: null,
      copied: false,
    };
  },
  methods: {
    ...mapActions("listing", ["setListings", "deleteListing", "cloneListing"]),
    async listingDelete() {
      this.$refs["delete-modal"].show();
      await this.deleteListing(this.selectedListing);
      this.toggleDeleteModal();
    },
    toggleDeleteModal(listingId, index) {
      this.$emit("toggleDeleteModal", { listingId, index });
    },
    getAppointmentRoute(listingIdx) {
      const status = this.listings.data[listingIdx].currentStatus;
      const id = this.listings.data[listingIdx].id;
      if (status === "Assign a candidate" || status === "Assigning Requested")
        return `/broker/listings-assign/${id}`;

      if (status == "rented") {
        return `/broker/listings/${id}/assigned`;
      }

      return "/broker/listings/" + id;
    },
    hasNewMessage(listingIdx) {
      if (
        !this.listings ||
        !this.listings.length ||
        !this.listings[listingIdx] ||
        !this.listings.appointments
      ) {
        return false;
      }

      return this.listings[listingIdx].appointments.find((appointment) => {
        console.log(appointment.newMessage);
        return appointment.newMessage;
      });
    },
    listingCurrentStatus(status) {
      const listingStatus = this.$t("listingStatus");
      return listingStatus[status];
    },
    async clone(listing_id) {
      console.log(listing_id);
      this.$emit("clone", listing_id);
    },

    delete() {
      console.log("delete");
    },
    copyListingLink(listing) {
      this.copyText(`${window.location.origin}/listings/${listing.slug}`);
    },
    async copyText(text) {
      try {
        await navigator.clipboard.writeText(text);
        this.copied = true;
        setTimeout(() => {
          this.copied = false;
        }, 2000);
        console.log("Text or Page URL copied");
      } catch (err) {
        console.error("Failed to copy: ", err);
      }
    },
  },
  computed: {
    ...mapGetters("listing", ["listings"]),
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
  },
};
</script>

<style lang="scss" scoped>
.controls {
  position: absolute;
  top: 22px;
  right: 24px;
  display: none;

  button {
    background: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    margin-left: 10px;
    width: 40px;
    height: 40px;
    border: none;
    display: flex;

    svg,
    img {
      margin: auto;
    }

    i {
      margin: auto;
      color: #fff;
    }
  }
}
.recent-listing:hover .controls {
  display: flex;
  justify-content: space-between;
  width: 90%;
}
</style>
