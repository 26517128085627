<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <!-- <div class="card-header">
          <div class="row">
            <div class="col-md-2 text-black">{{ $t("appointment") }}</div>
            <div class="col-md-10 text-black">{{ $t("property") }}</div>
          </div>
        </div> -->
        <div class="card-body p-0">
          <table class="table table-borderless table-hover">
            <thead>
              <tr class="border-bottom">
                <th></th>
                <th>
                  {{ $t("Address") }}
                </th>
                <th>
                  {{ $t("Status") }}
                </th>
                <th>
                  {{ $t("Price") }}
                </th>
                <th>
                  {{ $t("Messages") }}
                </th>
                <th>
                  {{ $t("Edit") }}
                </th>
                <th>
                  {{ $t("Action") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="border-bottom"
                v-for="(listing, index) in listings.data"
                v-bind:key="index"
              >
                <td class="py-4"></td>
                <td class="py-4">{{ listing.title }}</td>
                <td class="py-4">
                  <span
                    class="badge badge-primary p-2"
                    v-if="listing.currentStatus == 'Available'"
                  >
                    {{ $t(listing.currentStatus) }}
                  </span>
                  <span
                    class="badge badge-secondary p-2"
                    v-else-if="listing.currentStatus == 'cloned'"
                  >
                    {{ $t("Draft") }}
                  </span>
                  <span class="badge badge-secondary p-2" v-else>
                    {{ listing.currentStatus }}
                  </span>
                </td>
                <td class="py-4 text-gray">€ {{ listing.price }}</td>
                <td class="py-4 text-gray fw-700">{{ listing.messages_count }}</td>
                <td class="py-4">
                  <i
                    class="fa-solid fa-pen-to-square text-gray3 cursor-pointer mr-2"
                    @click="
                      $router.push({
                        name: 'BrokerEditListing',
                        params: { listingId: listing.id, mode: 'edit' },
                      })
                    "
                  ></i>
                  <i
                    class="fa-regular fa-clone text-gray3 cursor-pointer mr-2"
                    @click="clone(listing.id)"
                  ></i>
                  <i
                    class="fa-regular fa-trash-can text-gray3 cursor-pointer mr-2"
                    @click="toggleDeleteModal(listing.id, index)"
                  ></i>
                </td>
                <td class="py-4">
                  <router-link
                    v-if="listing.currentStatus == 'rented'"
                    :to="`/broker/listings/${listing.id}/assigned`"
                    class="btn btn-outline-primary btn-sm"
                  >
                    {{ $t("View assigned tenant") }}
                  </router-link>
                  <router-link
                    v-else-if="
                      listing.appointments &&
                      listing.appointments.length &&
                      ['Assign a candidate', 'Rented', 'Assigning Requested'].includes(
                        listing.currentStatus
                      )
                    "
                    :to="getAppointmentRoute(listing)"
                    class="btn btn-outline-primary btn-sm"
                  >
                    {{ $t("Assigning") }}
                  </router-link>
                  <router-link
                    v-else-if="listing.appointments && listing.appointments.length"
                    :to="getAppointmentRoute(listing)"
                    class="btn btn-outline-primary btn-sm"
                  >
                    {{ $t("Invite candidates") }}
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            class="d-flex col-md-12 justify-content-center text-gray text-center"
            v-if="listings.data.length == 0"
          >
            {{ $t("You don't have any listings yet.") }}
            <br />
            {{
              $t(
                'Please add a new listing by pressing the green button "Add listing" in the menu bar'
              )
            }}
          </div>
          <!-- <div
            class="row border-bottom"
            v-for="(listing, index) in listings.data"
            v-bind:key="index"
          >
            <div class="col-md-2">
              <span class="text-muted fs-16px">
                <span>
                  {{ listing.title }}
                </span>
              </span>
            </div>
            <div class="col-md-2">
              <Edit />
              <Duplicate />
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { debounce } from "../../../utils/debounce";

export default {
  props: ["search"],
  components: {},
  data() {
    return {
      currentListingIdx: null,
      activeFilter: "available",
      selectedListing: null,
    };
  },
  methods: {
    ...mapActions("listing", ["setListings", "deleteListing", "cloneListing"]),
    setFilteredListing(type) {
      this.activeFilter = type;
      this.$router.push({ path: this.$route.fullPath, query: { type } });
      this.setListings({ url: `/broker/listings?status=${type}` });
    },
    toggleDeleteModal(listingId, index) {
      this.$emit("toggleDeleteModal", { listingId, index });
    },
    getAppointmentRoute(listing) {
      const status = listing.currentStatus;
      const id = listing.id;
      if (
        status === "Assign a candidate" ||
        status === "Rented" ||
        status === "Assigning Requested"
      )
        return `/broker/listings-assign/${id}`;

      return "/broker/listings/" + id;
    },
    hasNewMessage(listingIdx) {
      if (
        !this.listings ||
        !this.listings.length ||
        !this.listings[listingIdx] ||
        !this.listings.appointments
      ) {
        return false;
      }

      return this.listings[listingIdx].appointments.find((appointment) => {
        console.log(appointment.newMessage);
        return appointment.newMessage;
      });
    },
    listingCurrentStatus(status) {
      const listingStatus = this.$t("listingStatus");
      return listingStatus[status];
    },
    async clone(listing_id) {
      console.log(listing_id);
      this.$emit("clone", listing_id);
    },
    delete() {
      console.log("delete");
    },
  },
  computed: {
    ...mapGetters("listing", ["listings"]),
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
  },
  watch: {
    search: debounce(function (newVal) {
      this.setListings({
        url: `/broker/listings?search=${newVal}`,
      });
    }, 500),
  },
  created() {},
};
</script>
