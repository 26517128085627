<template>
  <div class="container mb-5">
    <h1>
      {{ $t("My listings") }}
    </h1>

    <!-- filters -->
    <div
      class="broker__listings__filterContainer d-flex align-items-center"
      :class="{ 'justify-content-between': !listview, 'justify-content-end': listview }"
    >
      <div
        class="d-flex align-items-center align-self-end broker__listings__buttonsContainer"
        v-if="!listview"
      >
        <button
          class="btn broker__listings__button mb-2 mb-sm-0"
          :class="[{ active: 'all' === activeFilter }]"
          @click="setFilteredListing('all')"
          :disabled="'all' === activeFilter || getLoadings.listing"
        >
          {{ $t("All") }}
        </button>
        <button
          class="btn broker__listings__button ml-sm-2 ml-lg-4 ml-0 mb-2 mb-sm-0"
          :class="[{ active: 'available' === activeFilter }]"
          @click="setFilteredListing('available')"
          :disabled="activeFilter == 'available' || getLoadings.listing"
        >
          {{ $t("Available") }}
        </button>
        <button
          class="btn broker__listings__button ml-sm-2 ml-lg-4 ml-0 mb-2 mb-sm-0"
          :class="[{ active: 'Candidate selection' === activeFilter }]"
          @click="setFilteredListing('Candidate selection')"
          :disabled="activeFilter == 'Candidate selection' || getLoadings.listing"
        >
          {{ $t("Candidate Selection") }}
        </button>
        <button
          class="btn broker__listings__button ml-sm-2 ml-lg-4 ml-0 mb-2 mb-sm-0"
          :class="[{ active: 'Assign a candidate' === activeFilter }]"
          @click="setFilteredListing('Assign a candidate')"
          :disabled="'Assign a candidate' === activeFilter || getLoadings.listing"
        >
          {{ $t("Assign a candidate") }}
        </button>
        <button
          class="btn broker__listings__button ml-sm-2 ml-lg-4 ml-0 mb-2 mb-sm-0"
          :class="[{ active: 'rented' === activeFilter }]"
          @click="setFilteredListing('rented')"
          :disabled="'rented' === activeFilter || getLoadings.listing"
        >
          {{ $t("Rented") }}
        </button>
      </div>

      <div class="d-flex align-items-center">
        <div class="d-flex">
          <div class="mr-2">
            <div class="bg-white p-2 wh-40px text-center border rounded" v-if="!listview">
              <i class="fa-solid fa-border-all fs-20px"></i>
            </div>
            <div
              class="p-2 wh-40px text-center cursor-pointer"
              v-else
              @click="$router.push({ name: `BrokerListings` })"
            >
              <i class="fa-solid fa-border-all fs-20px"></i>
            </div>
          </div>
          <div class="mr-2">
            <div class="bg-white p-2 wh-40px text-center border rounded" v-if="listview">
              <i class="fa-solid fa-bars"></i>
            </div>
            <div
              class="p-2 wh-40px text-center cursor-pointer"
              v-else
              @click="$router.push({ name: `BrokerListingsListview` })"
            >
              <i class="fa-solid fa-bars"></i>
            </div>
          </div>
        </div>
        <label class="broker__listings__searchbox border mt-md-0 mt-4 mb-0" for="search">
          <input
            class="broker__listings__searchbox__input"
            type="text"
            v-model="search"
            :placeholder="$t('listingsKey.filters.searchplaceholder')"
          />
          <Magnify />
        </label>
      </div>
    </div>

    <spinner v-if="getLoadings.listings" />

    <template v-else>
      <Listview @clone="clone" @toggleDeleteModal="toggleDeleteModal" v-if="listview" />
      <Gridview
        @clone="clone"
        @toggleDeleteModal="toggleDeleteModal"
        :activeFilter="activeFilter"
        v-else
      />
    </template>

    <b-modal ref="delete-modal" centered hide-footer hide-header>
      <div class="container">
        <h3 class="text-dark-gray fw-700">
          {{ $t("Confirm deletion") }}
        </h3>
        <p>
          {{
            $t(
              "You are about to delete this object, press the button below to confirm this action."
            )
          }}
        </p>
        <div class="text-danger my-2" v-if="getErrors.delete_listing">
          {{ getErrors.delete_listing }}
        </div>
        <div class="d-flex justify-content-between">
          <button class="btn btn-white btn-sm" @click="toggleDeleteModal">
            {{ $t("Cancel") }}
          </button>
          <button
            class="btn btn-danger btn-sm"
            @click="listingDelete"
            :disabled="getLoadings.delete_listing"
          >
            <b-spinner
              v-if="getLoadings.delete_listing"
              small
              label="Spinning"
              variant="white"
            ></b-spinner>
            {{ $t("Delete Listing") }}
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
// import Right from "@/components/svg/Right.vue";
import Magnify from "@/components/svg/magnify.vue";
import { mapActions, mapGetters } from "vuex";
import Spinner from "@/components/ui/Spinner.vue";
import { debounce } from "../../../utils/debounce";
import Listview from "./Listview.vue";
import Gridview from "./Gridview.vue";

export default {
  components: {
    Spinner,
    Magnify,
    Listview,
    Gridview,
  },
  data() {
    return {
      search: "",
      currentListingIdx: null,
      activeFilter: "all",
      selectedListing: null,
    };
  },
  methods: {
    ...mapActions("listing", ["setListings", "deleteListing", "cloneListing"]),
    setFilteredListing(type) {
      this.activeFilter = type;
      this.$router.push({ name: "BrokerListings", query: { type } });
      // if (type == "all") {
      //   this.setListings({ url: `/broker/listings` });
      // } else {
      //   this.setListings({ url: `/broker/listings?status=${type}` });
      // }
    },
    async listingDelete() {
      this.$refs["delete-modal"].show();
      await this.deleteListing(this.selectedListing);
      this.$refs["delete-modal"].toggle();
    },
    toggleDeleteModal({ listingId, index }) {
      this.selectedListing = { listingId, index };
      this.$refs["delete-modal"].toggle();
    },
    getAppointmentRoute(listingIdx) {
      const status = this.listings.data[listingIdx].currentStatus;
      const id = this.listings.data[listingIdx].id;
      if (
        status === "Assign a candidate" ||
        status === "Rented" ||
        status === "Assigning Requested"
      )
        return `/broker/listings-assign/${id}`;
      return "/broker/listings/" + id;
    },
    hasNewMessage(listingIdx) {
      if (
        !this.listings ||
        !this.listings.length ||
        !this.listings[listingIdx] ||
        !this.listings.appointments
      ) {
        return false;
      }

      return this.listings[listingIdx].appointments.find((appointment) => {
        return appointment.newMessage;
      });
    },
    listingCurrentStatus(status) {
      const listingStatus = this.$t("listingStatus");
      return listingStatus[status];
    },
    async clone(listing_id) {
      await this.cloneListing({ listing_id });
      this.loadListings();
    },

    delete() {},
    loadListings() {
      this.activeFilter = this.$route.query.type || "all";
      if (this.listview) {
        this.setListings({ url: `/broker/listings` });
      } else {
        let status = this.activeFilter == "all" ? "" : this.activeFilter;
        this.setListings({ url: `/broker/listings?status=${status}` });
      }
    },
  },
  computed: {
    ...mapGetters("listing", ["listings"]),
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
    listview() {
      return this.$route.name == "BrokerListingsListview";
    },
  },
  watch: {
    search: debounce(function (newVal) {
      this.setListings({
        url: `/broker/listings?status=${this.activeFilter}&search=${newVal}`,
      });
    }, 500),
    $route() {
      this.loadListings();
    },
  },
  created() {
    this.loadListings();
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.listings__sort-select {
  padding: 0.9375rem 3rem 0.9375rem 2rem;
}

.broker__listings {
  padding-top: 51px;
  max-width: 1060px;
  margin-left: auto;
  margin-right: auto;

  @include lg {
    max-width: 900px;
  }

  @include md {
    max-width: 700px;
  }

  @include sm {
    max-width: 540px;
  }

  @include xs {
    max-width: 320px;
  }

  @include customMax(375px) {
    max-width: 280px;
  }

  &__heading {
    color: #005c17 !important;
    text-align: left !important;

    @include xs {
      text-align: center !important;
    }
  }

  &__filterContainer {
    padding-bottom: 12px;
    border-bottom: 2px solid #dcdcdc;
    margin-bottom: 45px;

    @include xs {
      flex-wrap: wrap;
      justify-content: center !important;
    }
  }

  &__buttonsContainer {
    position: relative;

    @include xs {
      width: 100%;
      flex-wrap: wrap;
      padding-top: 20px;
    }
  }

  &__button {
    padding: 0px !important;
    @include font(1rem, $text-gray-inactive, 600);
    @include xs {
      width: 90%;
      margin-left: auto !important;
      margin-right: auto !important;
      padding: 0.5rem 1.3rem !important;
    }
  }

  &__button.active {
    position: relative;
    @include font(1rem, white, 700);
    color: #005c17 !important;
    opacity: 1 !important;

    @include md {
      padding: 1rem 2rem !important;
    }

    @include sm {
      padding: 1rem 1.3rem !important;
      font-size: 0.9rem;
    }

    @include xs {
      padding: 0.5rem 1.3rem !important;
      width: 90%;
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }

  &__button.active::before {
    content: "";
    position: absolute;
    bottom: -14px;
    left: 0;
    width: 100%;
    height: 2px;
    background: #005c17;

    @include xs {
      background: none;
    }
  }

  &__searchbox {
    border-radius: 5px;
    padding: 10px;
    background: white;
    justify-content: space-between;
    display: flex;

    &__input {
      border: none !important;
      background: transparent;
      color: $primary;
      width: 90%;
    }

    &__input:focus-visible {
      outline: none !important;
    }
  }

  &__controls {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;
    padding-right: 23px;

    @include md {
      flex-direction: row;
      align-items: center;
      padding-right: 0px;
      padding-top: 20px;
      padding-bottom: 10px;
    }

    @include xs {
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 20px;
    }

    &__status {
      @include font(1rem, black, 500);

      @include xs {
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }

    &__status.draft {
      transform: translateY(45px);

      @include md {
        transform: translateY(0);
        padding-right: 30px;
        flex-basis: 320px;
        text-align: center;
      }

      @include sm {
        padding-right: 0px;
        flex-basis: 350px;
      }

      @include xs {
        flex-basis: auto;
      }
    }

    &__messages {
      max-width: 202px;
      margin-top: 8px;
      margin-bottom: 20px;
      padding: 5px 13px !important;

      @include md {
        margin-bottom: 10px;
      }

      &__text {
        @include font(0.9375rem, $gray, 500);
      }
    }

    &__actions {
      margin-bottom: 8px;

      @include md {
        margin-bottom: 0;
      }
    }

    &__actions.draft {
      transform: translateY(20px);

      @include md {
        transform: translateY(0);
      }
    }

    &__alternate {
      width: 51px;
    }
  }

  &__controls.draft {
    @include md {
      justify-content: space-around;
      padding: 0 0px 20px 0;
    }
  }

  &__info {
    &__text {
      @include font(1rem, $gray, 500);

      @include sm {
        font-size: 0.9rem;
      }
    }
  }

  &__add {
    width: 202px;
    justify-content: space-around;
    padding-top: 12px !important;
    padding-bottom: 12px !important;

    @include md {
      width: 162px;
    }

    @include sm {
      width: 132px;
    }

    @include xs {
      padding-top: 14px !important;
      padding-bottom: 14px !important;
      width: 60%;
    }

    &__text {
      @include font(1rem, $dark2, bold);
    }
  }

  &__delete {
    font-size: 14px;
  }
}

.controls-text {
  @include font(1rem, #8784aa, 500);
}

.controls-text.noMessage {
  transform: translateY(-7px);
}

.listing {
  &__caption {
    bottom: 0;
    padding: 10px 0;

    &__text {
      @include font(0.75rem, $white, 700);
    }
  }
}

.recent-listing {
  position: relative;
}
</style>
