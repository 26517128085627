<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.16667 8.33301H7.5V10.833H5V12.4997H7.5V14.9997H9.16667V12.4997H11.6667V10.833H9.16667V8.33301Z"
            fill="white" />
        <path
            d="M3.33317 18.3333H13.3332C14.2523 18.3333 14.9998 17.5858 14.9998 16.6667V6.66667C14.9998 5.7475 14.2523 5 13.3332 5H3.33317C2.414 5 1.6665 5.7475 1.6665 6.66667V16.6667C1.6665 17.5858 2.414 18.3333 3.33317 18.3333ZM3.33317 6.66667H13.3332L13.3348 16.6667H3.33317V6.66667Z"
            fill="white" />
        <path
            d="M16.6665 1.66699H6.6665V3.33366H16.6665V13.3337H18.3332V3.33366C18.3332 2.41449 17.5857 1.66699 16.6665 1.66699Z"
            fill="white" />
    </svg>
</template>
  
<script>
export default {};
</script>
  
<style lang="scss" scoped>

</style>
  